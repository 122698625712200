<script setup lang="ts">
const prepareImgUrl = useImage();

const prizeBoxIcon = `url(${prepareImgUrl("/nuxt-img/deposit-streak/pumpkin-icon.webp", {
	format: "avif",
	loading: "lazy"
})})`;
const progressActiveIcon = `url(${prepareImgUrl("/nuxt-img/deposit-streak/progress-active.svg")})`;
const progressCompleteIcon = `url(${prepareImgUrl("/nuxt-img/deposit-streak/progress-complete.svg")})`;

const { t } = useT();

const props = withDefaults(
	defineProps<{
		levels?: number;
		active?: number;
		mode?: "horizontal" | "vertical";
	}>(),
	{
		levels: 8,
		active: 0,
		mode: "vertical"
	}
);

const progressBarWidth = computed(() => {
	if (props.levels <= props.active) {
		return "100%";
	}
	if (props.active < props.levels) {
		return `${(props.active * 100) / props.levels}%`;
	}
	return "0%";
});
</script>

<template>
	<div :class="['progress-block', mode]">
		<div class="wrapper">
			<div class="progress-bar">
				<div class="progress-grid">
					<div v-for="level in levels" :key="`level-item-${level}`" class="progress-level">
						<div :class="['level-icon', { completed: level <= active }]">
							<AText
								v-if="level > active"
								:size="mode === 'vertical' ? 16 : 10"
								class="level-value"
								:modifiers="['bold']"
								as="div"
							>
								{{ level }}
							</AText>
						</div>
					</div>
				</div>
				<div class="gradient-box">
					<div class="gradient-progress">
						<div class="gradient-current" />
					</div>
				</div>
			</div>
			<AText
				v-if="mode === 'vertical'"
				:size="10"
				class="text-coro"
				:modifiers="['center', 'semibold', 'uppercase']"
				as="div"
			>
				{{ t("Purchases done") }}
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.progress-block {
	position: relative;

	&.vertical {
		height: 373px;
		width: 70px;
		border-radius: 8px;
		background: var(--clamart);
		padding: 1px;
		box-shadow: 0px 4px 7px 0px var(--coronel);

		.wrapper {
			width: 100%;
			display: flex;
			align-items: flex-end;
			border-radius: inherit;
			height: 100%;
			padding: 9px 0;
			border: 1px solid var(--caldwell);
			background: var(--gallarate);
		}

		.progress-bar {
			left: 50%;
			top: -28px;
			transform: translateX(-50%);
			height: calc(100% - 45px);
			width: 20px;
			border-radius: 10px;
			padding: 2px;
			background: var(--gostivar);
			position: absolute;
			margin: 24px 0 10px;
		}

		.progress-grid {
			flex-direction: column-reverse;
		}

		.progress-level:last-child::after {
			width: 40px;
			height: 40px;
			left: calc(50% - 20px);
			top: -22px;
		}

		.level-icon {
			width: 32px;
			height: 32px;
			position: absolute;
			left: calc(50% - 16px);
			bottom: -8px;
		}

		.gradient-box {
			padding: 2px;
		}

		.gradient-current {
			width: 100%;
			height: v-bind(progressBarWidth);
		}
	}

	&.horizontal {
		height: 24px;
		width: calc(100% - 20px);
		margin-left: 6px;

		.wrapper {
			display: flex;
			align-items: center;
			height: 100%;
		}

		.progress-bar {
			height: 16px;
			width: 100%;
			padding: 1px;
			background: var(--gostivar);
		}

		.progress-level:last-child::after {
			width: 24px;
			height: 24px;
			right: -12px;
			top: -1px;
		}

		.level-icon {
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			left: -12px;
		}

		.level-value {
			line-height: 20px;
		}

		.gradient-box {
			padding: 1px 0 2px;
		}

		.gradient-current {
			height: 100%;
			width: v-bind(progressBarWidth);
		}
	}

	.progress-grid {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.progress-level {
		flex: 1;
		position: relative;

		&:last-child::after {
			content: "";
			background: v-bind(prizeBoxIcon) center/cover no-repeat;
			position: absolute;
		}
	}

	.level-icon {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background: v-bind(progressActiveIcon) center/cover no-repeat;

		&.completed {
			background: v-bind(progressCompleteIcon) center/cover no-repeat;
		}
	}

	.level-value {
		color: var(--colegiales);
	}

	.gradient-box {
		background: var(--gajraula);
		width: 100%;
		height: 100%;
		border-radius: inherit;
	}

	.gradient-progress {
		background: var(--ghatal);
		width: 100%;
		border-radius: inherit;
		display: flex;
		align-items: flex-end;
		height: 100%;
	}

	.gradient-current {
		border-radius: inherit;
		transition: all 500ms ease-in-out;
		background: var(--guercif);
	}
}
</style>
